.invoice-details-header {
	font-size: 14px;
	font-weight: bold;
	padding: 5px;
	display: inline-flex;
	width: 100%;
	align-items: center;
}
.invoice-number {
	background-color: rgb(33 150 243);
	color: white;
	padding: 5px;
	border-radius: 5px;
}
.invoice-status {
	margin-left: 15px;
	padding: 5px;
	border-color: rgb(33 150 243);
	border-style: solid;
	border-width: thin;
	border-radius: 5px;
}
.billing-type {
	margin-left: 10px;
	padding: 5px;
	font-size: 12px;
	color: white;
	background-color: rgb(33 150 243);
	border-radius: 5px;
	cursor: default;
}
.dispute-resolved {
	margin-left: 15px;
}
.title-l2 {
	font-size: 16px;
	color: gray;
	font-weight: bold;
	padding: 5px;
}
.invoice-details {
	font-size: 12px;
	display: inline-flex;
	width: 100%;
	padding: 5px;
}
.invoice-details-column {
	width: 33%;
	display: grid;
	padding-left: 5px;
}
.invoice-details-row {
	display: inline-flex;
	width: 100%;
	padding: 2px;
}
.invoice-activity-item {
	display: inline-flex;
	align-items: center;
	width: 90%;
	padding: 2px;
}
.inv-detail-field-name {
	width: 40%;
	font-weight: bold;
}
.inv-detail-field-val {
	width: 60%;
}
.invoice-attachments,
.invoice-activities {
	font-size: 12px;
}
.tab-list {
	width: 25%;
	display: inline-flex;
	font-size: 14px;
}
.tab {
	width: 33%;
	text-align: center;
	cursor: pointer;
}
.active {
	border-bottom-style: solid;
	border-bottom-width: medium;
	border-bottom-color: rgb(33 150 243);
}
.activity-user {
	width: 15%;
	font-weight: bold;
	margin-left: 5px;
}
.activity-comment {
	width: 30%;
	margin-left: 10px;
}
.inv-details-main-header {
	height: 10%;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
	margin: 10px;
	padding: 10px;
	align-content: center;
}
.invoice-details-outer-container {
	height: 90%;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
	margin: 10px;
	padding: 10px;
}
.nav-text {
	vertical-align: middle;
}
.nav-link {
	text-decoration: none;
	color: black;
	cursor: pointer;
}
.nav-link:hover {
	text-decoration: underline;
}
.nav-link:visited {
	color: black;
}
.attach-link:visited {
	color: blue;
}
.profile-icon {
	color: rgb(33 150 243);
}
.invoice-details-container,
.invoice-attachments-container,
.invoice-activity-container {
	padding: 5px;
}
.actvity-tab-baseline {
	margin: 0px;
}
.invoice-activities {
	margin-top: 5px;
}
.check-icon {
	vertical-align: bottom;
}
.add-button-text {
	margin-left: 5px;
}
.add-comment-container {
	margin-top: 5px;
	height: 25px;
}
.comment-input,
.add-comment-button {
	height: 100%;
}
.add-button-container {
	margin-left: 5px;
	vertical-align: bottom;
}
.label-edit-icon {
	height: 10px;
}
.labels-container {
	height: 100%;
}
.MuiChip-root {
	height: auto !important;
	margin-left: 0px !important;
	margin-right: 8px;
}
.inv-detail-field-name,
.inv-detail-field-val {
	padding: 1px;
}
.comment-input {
	font-size: 12px !important;
}
.add-button-text {
	font-size: 12px !important;
	text-transform: none;
}
.comment-icon {
	height: 14px !important;
	width: 14px !important;
}
.comment-timestamp {
	font-weight: bold;
	margin-left: 5px;
	width: 15%;
}
.activity {
	display: flex;
}
.attach-list {
	margin: 0px;
}
