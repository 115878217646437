.react-datepicker-wrapper {
	width: inherit !important;
}

.react-datepicker {
	background-color: white !important;
	border: 2px solid black !important;
	border-radius: 3%;
}

.react-datepicker__header {
	background-color: #2196f3 !important;
	border: 1px solid #2196f3 !important;
}

.react-datepicker__current-month,
.react-datepicker__day-name {
	color: white !important;
}

.react-datepicker__day:hover {
	background-color: #2196f3 !important;
	color: white !important;
}

.react-datepicker__day--selected,
.react-datepicker__day--selected:hover {
	background-color: #1e88e5 !important;
	color: white !important;
}

.react-datepicker__triangle {
	display: none;
}

.react-datepicker-popper {
	border-radius: 2%;
}

.react-datepicker__month-select {
	border: 2px solid #90caf9;
	border-radius: 10px;
	background-color: #f5f5f5;
	color: #333;
	height: 30px;
}

.react-datepicker__year-select {
	border: 2px solid #90caf9;
	border-radius: 10px;
	background-color: #f5f5f5;
	color: #333;
	height: 30px;
}
