.loading-row {
    animation: loading-animation 1.5s infinite ease-in-out;
}
  
.loading-cell {
    height: 20px; /* Adjust height as needed */
    background: linear-gradient(
        to right,
        #f0f0f0 10%,
        #e0e0e0 50%,
        #f0f0f0 90%
    ); /* Example gradient, adjust colors */
    background-size: 200% auto;
    animation: loading-animation 1.5s infinite ease-in-out;
}
  
@keyframes loading-animation {
    0% {
        background-position: 200% 0;
    }
    100% {
        background-position: -200% 0;
    }
}